import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`A `}<inlineCode parentName="p">{`Fieldset`}</inlineCode>{` is used to semantic group several form elements within a web form.`}</p>
    <p>{`Usa a `}<inlineCode parentName="p">{`Fieldset`}</inlineCode>{` to semantic group several form elemens, like `}<inlineCode parentName="p">{`Checkboxes`}</inlineCode>{` and
`}<inlineCode parentName="p">{`Radio's`}</inlineCode>{`, within a web form.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const values = ['Antwerp', 'Brussels', 'Hasselt', 'Mechelen'];
  const [checkedValue, setCheckedValue] = React.useState(values[0]);
  const isChecked = value => value === checkedValue;

  return (
    <Fieldset label="The capital of Belgium">
      {values.map(value => (
        <Box key={value} mb={5}>
          <Radio
            name="buggles"
            id={\`label-\${value}\`}
            value={value}
            label={value}
            checked={isChecked(value)}
            onChange={e => setCheckedValue(e.target.value)}
          />
        </Box>
      ))}
    </Fieldset>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "components/checkbox/code"
        }}>{`CheckBox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "components/radio/code"
        }}>{`Radio`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      